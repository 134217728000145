import { render, staticRenderFns } from "./form-editor.html?vue&type=template&id=5d8b8e3e&scoped=true&external"
import script from "./form-editor.js?vue&type=script&lang=js&external"
export * from "./form-editor.js?vue&type=script&lang=js&external"
import style0 from "./form-editor.scss?vue&type=style&index=0&id=5d8b8e3e&prod&scoped=true&lang=scss&external"


/* normalize component */
import normalizer from "!../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "5d8b8e3e",
  null
  
)

export default component.exports